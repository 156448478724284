import { render, staticRenderFns } from "./newSubmit.vue?vue&type=template&id=40e06160&scoped=true&"
import script from "./newSubmit.vue?vue&type=script&lang=js&"
export * from "./newSubmit.vue?vue&type=script&lang=js&"
import style0 from "./newSubmit.vue?vue&type=style&index=0&id=40e06160&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40e06160",
  null
  
)

export default component.exports