<template>
  <validation-observer
    ref="rulesForm"
    tag="form"
  >
    <!-- 收件人 -->
    <b-row>
      <b-col
        v-show="isTw"
        cols="12"
        class="mb-0"
      >
        <b-alert
          variant="warning"
          show
        >
          <div class="alert-body">
            <span>根据台湾财政部关务署规定，请务必提醒收件人完成易利委（ezapp）实名认证，以确保包裹顺利清关，详情请<strong
              style="text-decoration: underline;cursor: pointer;"
              @click="handleGoEzapp"
            >点击查看</strong></span>
          </div>
        </b-alert>
        <b-alert
          variant="danger"
          show
        >
          <div class="alert-body">
            <span><strong>请使用中文填写以下信息</strong></span>
          </div>
        </b-alert>
      </b-col>
      <b-col md="6">
        <validation-provider
          #default="{ errors }"
          name="country"
          rules="required"
        >
          <b-form-group
            label="国家"
            label-for="country"
            :state="errors.length > 0 ? false:null"
          >
            <v-select
              id="country"
              v-model="form.country"
              :disabled="isShipmentDetail || !isEdit"
              placeholder="请选择国家"
              :options="countryList"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="邮编"
          label-for="postcode"
        >
          <validation-provider
            #default="{ errors }"
            name="postcode"
            rules="required"
          >
            <b-form-input
              id="postcode"
              v-model.trim="form.postcode"
              :disabled="isShipmentDetail || !isEdit"
              :state="errors.length > 0 ? false:null"
              placeholder="请输入邮编"
              @blur="handlePostcodeBlur"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            <small
              v-if="postError"
              class="text-danger"
            >查找不到对应的市区，请核对收件地址的邮编</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        v-if="isTw"
        md="6"
      >
        <b-form-group
          label="区"
          label-for="suburb"
        >
          <validation-provider
            #default="{ errors }"
            name="suburb"
            rules="required"
          >
            <v-select
              id="suburb"
              v-model="form.suburb"
              placeholder="请选择区"
              :disabled="isShipmentDetail || !isEdit"
              :options="suburbList"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              @input="handleSuburbInput"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        v-if="isTw"
        md="6"
      >
        <b-form-group
          label="市"
          label-for="state"
        >
          <validation-provider
            #default="{ errors }"
            name="state"
            rules="required"
          >
            <v-select
              id="state"
              v-model="form.state"
              placeholder="请选择市"
              :disabled="isShipmentDetail || !!form.suburb || !isEdit"
              :options="stateList"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              @input="handleStateInput"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="收件人名称"
          label-for="recipient_name"
        >
          <validation-provider
            #default="{ errors }"
            rules="required"
            name="recipient_name"
          >
            <b-form-input
              id="recipient_name"
              v-model="form.recipient_name"
              :disabled="!isEdit"
              :state="errors.length > 0 ? false:null"
              placeholder="请输入收件人名称"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="公司名称"
          label-for="company_name"
        >
          <validation-provider
            #default="{ errors }"
            name="company_name"
          >
            <b-form-input
              id="company_name"
              v-model="form.company_name"
              :disabled="!isEdit"
              :state="errors.length > 0 ? false:null"
              placeholder="请输入公司名称"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        v-if="!isTw"
        md="6"
      >
        <b-form-group
          label="市/区"
          label-for="suburb"
        >
          <validation-provider
            #default="{ errors }"
            name="suburb"
            rules="required"
          >
            <b-form-input
              v-if="!isStateDisabled"
              id="suburb"
              v-model="form.suburb"
              :disabled="isShipmentDetail || !isEdit"
              :state="errors.length > 0 ? false:null"
              placeholder="请输入市/区"
            />
            <v-select
              v-else
              id="suburb"
              v-model="form.suburb"
              placeholder="请选择市/区"
              :options="suburbList"
              :disabled="isShipmentDetail || !isEdit"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              @input="handleSuburbInput"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        v-if="!isTw"
        md="6"
      >
        <b-form-group
          label="省"
          label-for="state"
        >
          <validation-provider
            #default="{ errors }"
            name="state"
            rules="required"
          >
            <v-select
              id="state"
              v-model="form.state"
              placeholder="请选择省"
              :disabled="isShipmentDetail || isStateDisabled || !isEdit"
              :options="stateList"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              @input="handleState"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          label="地址1"
          label-for="address"
        >
          <validation-provider
            #default="{ errors }"
            name="address"
            rules="required"
          >
            <b-form-input
              id="address"
              v-model="form.address"
              :disabled="!isEdit"
              :state="errors.length > 0 ? false:null"
              placeholder="请输入地址1"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          label="地址2"
          label-for="address2"
        >
          <validation-provider
            #default="{ errors }"
            name="address2"
          >
            <b-form-input
              id="address2"
              v-model="form.address_line_2"
              :disabled="!isEdit"
              :state="errors.length > 0 ? false:null"
              placeholder="请输入地址2"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          label="地址3"
          label-for="address3"
        >
          <validation-provider
            #default="{ errors }"
            name="address3"
          >
            <b-form-input
              id="address3"
              v-model="form.address_line_3"
              :disabled="!isEdit"
              :state="errors.length > 0 ? false:null"
              placeholder="请输入地址3"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col :md="!isTw ? 6 : 4">
        <b-form-group
          label="电话"
          label-for="rphone"
        >
          <validation-provider
            #default="{ errors }"
            name="rphone"
            rules="required"
          >
            <b-input-group :prepend="'+' + form.phone_code">
              <b-form-input
                v-model.trim="form.contact_number"
                :disabled="!isEdit"
                placeholder="请输入电话号码"
                type="number"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col :md="!isTw ? 6 : 4">
        <b-form-group
          label="邮箱"
          label-for="email"
        >
          <validation-provider
            #default="{ errors }"
            name="email"
          >
            <b-form-input
              id="email"
              v-model.trim="form.email"
              :disabled="!isEdit"
              :state="errors.length > 0 ? false:null"
              placeholder="请输入邮箱"
            />
            <small v-if="emailError" class="text-danger">邮箱格式不正确，请检查</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        v-if="isTw"
        md="4"
      >
        <b-form-group
          label="身份证"
          label-for="id_card"
        >
          <validation-provider
            #default="{ errors }"
            name="id_card"
            rules="required"
          >
            <b-form-input
              id="id_card"
              v-model.trim="form.id_card"
              :disabled="!isEdit"
              :state="errors.length > 0 ? false:null"
              placeholder="请输入身份证"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            <small
              v-if="idCardError"
              class="text-danger"
            >请输入正确的台湾身份证号</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { required } from '@validations'
import {
  // BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BAlert,
  BInputGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    // BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BAlert,
    vSelect,
    BInputGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    isShipmentDetail: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      form: {
        country_id: '',
        country: '',
        postcode: '',
        recipient_name: '',
        company_name: '',
        suburb: '',
        state: '',
        state_id: '',
        address: '',
        address_line_2: '',
        address_line_3: '',
        phone_code: '',
        contact_number: '',
        email: '',
        id_card: '',
      },
      countryList: [],
      suburbList: [],
      isStateDisabled: false,
      stateList: [],
      isTw: false,
      postError: false,
      idCardError: false,
      emailError: false,
    }
  },
  computed: {
    recipient() {
      return this.$store.getters['shipment/recipient']
    },
  },
  watch: {
    form: {
      handler(val) {
        this.$store.commit('shipment/setShipmentRecipient', val)
      },
      deep: true,
    },
    // eslint-disable-next-line
    'form.country': function (val) {
      if (val.value) {
        this.form.postcode = ''
        this.form.state = ''
        this.form.state_id = ''
        this.form.suburb = ''
        this.form.phone_code = val.phonecode
        this.form.country = val.text
        this.form.country_id = val.value
        if (val.value === 13 || val.value === 132 || val.value === 157 || val.value === 214) {
          this.isStateDisabled = true
        } else {
          this.isStateDisabled = false
          this.handleStateList()
        }
        if (val.value === 214) {
          this.isTw = true
        } else {
          this.isTw = false
        }
      }
    },
    // eslint-disable-next-line
    'form.id_card': function (val) {
      if (val) {
        if (!/^[a-zA-Z]{1}[a-zA-Z0-9]{1}[0-9]{8}$/.test(val) && this.form.country_id === 214) {
          this.idCardError = true
        } else {
          this.idCardError = false
        }
      }
    },
    // eslint-disable-next-line
    'form.email'(val) {
      if (val) {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        // eslint-disable-next-line
        this.emailError = !emailPattern.test(val)
      } else {
        this.emailError = false
      }
    },
    recipient: {
      async handler(val) {
        if (val.country) {
          // eslint-disable-next-line
          for (const k in this.form) {
            // eslint-disable-next-line
            val.name ? this.form.recipient_name = val.name : ''
            // eslint-disable-next-line
            val.phoneCode ? this.form.phone_code = val.phoneCode : ''
            // eslint-disable-next-line
            val.contactNumber ? this.form.contact_number = val.contactNumber : ''
            // eslint-disable-next-line
            val.country_id ? this.form.country_id = val.country_id : ''
            this.form[k] = val[k]
          }

          if (this.form.country_id === 214) {
            this.isTw = true
          } else {
            this.isTw = false
          }
        }
        await this.initCountry()
        this.handlePostcodeBlur()
        this.$api.saveOperateLog({
          code: 'agent-recipient-form',
          content: JSON.stringify(this.form),
        })
      },
      immediate: true,
    },
  },
  methods: {
    handleState(e) {
      this.form.state = e.text
      this.form.state_id = e.value
    },
    async handleStateInput(e) {
      try {
        const { data } = await this.$api.autoPostcodeSuburb({ country_id: this.form.country.value, state_id: e.value })
        this.suburbList = data.map(item => ({
          value: item.name,
          text: item.name,
        }))
      } catch (error) {
        console.log('error', error)
      }
    },
    handleGoEzapp() {
      window.open('https://ebaoguo.com/kb/ezapp/')
    },
    async handleStateList() {
      try {
        const { data } = await this.$api.stateList({ page: 1, perPage: 1000, country_id: this.form.country_id })
        this.stateList = data.map(item => ({
          text: item.name,
          value: item.id,
        }))
      } catch (error) {
        console.log('error', error)
      }
    },
    handleSuburbInput(e) {
      this.form.state = e.state
      this.form.state_id = e.state_id
      this.form.suburb = e.text
    },
    async handlePostcodeBlur() {
      // eslint-disable-next-line
      const country_id = arguments[1] ? arguments[1] : this.form.country_id
      try {
        if (country_id && (country_id === 13 || country_id === 132 || country_id === 157 || country_id === 214)) {
          const { data } = await this.$api.autoPostcodeSuburb({ country_id, postcode: this.form.postcode })
          if (data.length) {
            this.postError = false
            this.suburbList = data.map(item => ({
              value: item.state_id,
              text: item.name,
              ...item,
            }))
            this.suburbList = this.rDuplicate(this.suburbList, 'name')
            this.form.suburb = this.suburbList[0].name
            this.form.state = this.suburbList[0].state
            this.form.state_id = this.suburbList[0].state_id
          } else {
            this.suburbList = []
            this.form.suburb = ''
            this.form.state = ''
            this.form.state_id = ''
            this.postError = true
          }
          this.$api.saveOperateLog({
            code: 'agent-autoPostcodeSuburb',
            content: JSON.stringify(this.form),
          })
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error', error)
      }
    },
    async initCountry() {
      try {
        const { data } = await this.$api.countryList({ page: 1, perPage: 1000 })
        this.countryList = data.map(item => ({
          value: item.id,
          text: item.country,
          ...item,
        }))
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error', error)
      }
    },
    validationForm() {
      return new Promise(resolve => {
        this.$refs.rulesForm.validate().then(success => {
          if (success && !this.idCardError && !this.emailError) {
            this.$store.commit('shipment/setShipmentRecipient', this.form)
            resolve(true)
          } else {
            resolve(false)
          }
        })
      })
    },
    rDuplicate(arr, key) {
      const temp = []
      arr.forEach(a => {
        const check = temp.every(b => a[key] !== b[key])
        // eslint-disable-next-line
        check ? temp.push(a) : ''
      })
      return temp
    },
  },
}
</script>

<style lang="scss" scoped>
.input-group-text{
  font-size: 1.1rem;
}
</style>
