<template>
  <div>
    <b-overlay
      :show="overlayShow"
      rounded="sm"
    >
      <b-card>
        <h3 class="mb-1 card-title border-b">
          发件人
        </h3>
        <b-row>
          <b-col
            cols="6"
            class="mb-0"
          >
            <b-form-group
              label="发件人姓名"
              label-for="sender_name"
            >
              <b-form-input
                id="postcode"
                v-model="sender.sender_name"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            class="mb-0"
          >
            <b-form-group
              label="发件人电话"
              label-for="sender_name"
            >
              <b-form-input
                id="postcode"
                v-model="senderMobile"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <h3 class="mb-1 card-title border-b">
          收件人
        </h3>
        <receiver
          ref="receiver"
          :isEdit="isEdit"
        />
        <div class="d-flex justify-content-end">
          <b-button
            v-show="!isEdit"
            variant="relief-primary"
            @click="handleEditRecipient"
          >
            修改
          </b-button>
          <div v-show="isEdit">
            <b-button
              variant="relief-info"
              class="mr-1"
              @click="handleModifyRecipient"
            >
              取消
            </b-button>
            <b-button
              variant="relief-success"
              @click="handleSaveRecipient"
            >
              保存
            </b-button>
          </div>
        </div>
      </b-card>
      <b-card>
        <h3 class="mb-1 card-title border-b">
          包裹信息
        </h3>
        <b-alert
          variant="primary"
          show
        >
          <div
            v-if="is_include_battery || recipient.country_id === 196 || recipient.country_id === 214 || recipient.country_id === 132"
            class="alert-body"
          >
            <span v-if="recipient.country_id === 196"><strong>包裹邮寄新加坡，一个预报运单对应一个包裹；如果有多个包裹，请做多张预报单</strong></span>
            <span v-if="recipient.country_id === 214"><strong>包裹邮寄台湾，一个预报运单对应一个包裹；如果有多个包裹，请做多张预报单。同名预报单会分批发货</strong></span>
            <span v-if="recipient.country_id === 132"><strong>包裹邮寄马来西亚，多个包裹发往同一个地址，只需要做一个预报单，一票多件</strong></span>
            <span v-if="is_include_battery"><strong>该包裹含有电池，请与客人确认</strong></span>
          </div>
        </b-alert>
        <add-parcel
          ref="addParcel"
          :tagList="tagList"
          :typeList="typeList"
          :parcels="parcels"
          :shipmentId="shipment_id"
          :country_id="recipient.country_id"
          :is_include_battery="is_include_battery"
          @delParcel="handleDelParcel"
          @change="handleAddParcelChange"
        />
      </b-card>
      <b-card>
        <div class="mb-2 card-title border-b d-flex justify-content-between align-items-center">
          <h3>
            选择物流
          </h3>
          <b-button
            variant="relief-success"
            @click="predictionLogistics"
          >
            获取物流
          </b-button>
        </div>
        <b-table
          ref="selectableTable"
          selectable
          :items="items"
          :fields="fields"
          responsive="sm"
          selectMode="single"
          @row-selected="onRowSelected"
        >
          <template #row-details="row">
            <b-card
              class="shadow-none"
            >
              <b-row class="mb-2">
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>物流附加费用 : </strong>AU${{ row.item.extra_fee }}
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>海关费用 : </strong>AU${{ row.item.tax_fee }}
                </b-col>
              </b-row>
            </b-card>
          </template>
          <template #cell(radio)="data">
            <b-form-radio
              v-model="data.value"
              plain
              name="radio"
              size="lg"
              :value="data.item.id"
              @change="handleRadioChange"
            />
          </template>
          <template #cell(logo)="data">
            <b-avatar
              variant="light-secondary"
              size="50px"
              square
              :src="data.value"
            />
          </template>
          <template #cell(estimation)="data">
            <span>AU${{ data.value }}</span>
          </template>
          <template #cell(duration)="data">
            <span>{{ data.value }}天</span>
          </template>
          <template #cell(include_tax)="data">
            <span>{{ data.value == 1 ? '是' : '否' }}</span>
          </template>
          <template #cell(weight)="data">
            <span>{{ data.value }}KG</span>
          </template>
          <template #cell(other)="data">
            <span>{{ data.item.weight_calc_type_text }}</span>
          </template>
        </b-table>
        <div
          v-if="isLogiLoading"
          class="d-flex justify-content-center align-items-center"
        >
          <b-spinner
            type="grow"
            variant="warning"
            class="mr-1"
          />
          <span class="loading-text">Loading...</span>
        </div>
        <div
          v-if="!isLogiLoading && items && !items.length"
          class="text-center"
        >
          暂时没有物流可选择，请联系客服
        </div>
      </b-card>
      <b-card>
        <h3 class="mb-1 card-title border-b">
          申报信息
        </h3>
        <b-alert
          variant="primary"
          show
        >
          <div
            class="alert-body"
          >
            <span><strong>如果包裹夹带违禁品或限制物品，所有退运费用、海关费用，罚款和责任将由发件人负责，并按账单实际费用结算。</strong></span>
          </div>
        </b-alert>
        <div v-if="!isDeclareEdit">
          <b-row>
            <b-col
              class="declare-title"
              xl="4"
              lg="4"
              md="4"
              sm="6"
            >产品名称</b-col>
            <b-col
              class="declare-title"
              xl="4"
              lg="4"
              md="4"
              sm="6"
            >数量</b-col>
            <b-col
              class="declare-title"
              xl="4"
              lg="4"
              md="4"
              sm="6"
            >总价值</b-col>
          </b-row>
          <hr>
          <b-row
            v-for="item in declareList"
            :key="item.id"
          >
            <b-col
              class="mb-50"
              style="text-align: center"
              xl="4"
              lg="4"
              md="4"
              sm="6"
            >{{ item.name }}</b-col>
            <b-col
              class="mb-50"
              style="text-align: center"
              xl="4"
              lg="4"
              md="4"
              sm="6"
            >{{ item.qty }}</b-col>
            <b-col
              class="mb-50"
              style="text-align: center"
              xl="4"
              lg="4"
              md="4"
              sm="6"
            >AU${{ item.value }}</b-col>
          </b-row>
          <hr>
          <div class="price">
            申报金额: AU${{ declareTotalPrice }}
          </div>
        </div>
        <declare
          v-if="isDeclareEdit"
          ref="declare"
          :declare="declareList"
        />
        <div class="d-flex justify-content-end">
          <b-button
            v-show="!isDeclareEdit"
            variant="relief-primary"
            @click="isDeclareEdit = true"
          >
            修改
          </b-button>
          <b-button
            v-show="isDeclareEdit"
            variant="relief-success"
            @click="handleSaveDeclare"
          >
            保存
          </b-button>
        </div>
      </b-card>
      <b-card>
        <h3 class="mb-1 card-title border-b">
          保险
        </h3>
        <div class="check-wrap">
          <b-form-checkbox
            v-model="insSelected"
            :disabled="!selected.length || !insPrice"
            value="A"
          >
            <span class="ins">我要购买保险</span>
          </b-form-checkbox>
          <div class="mt-1 ins">
            根据您的申报，您的保险费用为 AU${{ insPrice }}
          </div>
        </div>
      </b-card>
      <b-card>
        <div class="card-title border-b mb-2 d-flex justify-content-between align-items-center">
          <h3 style="margin:0;">
            额外服务
          </h3>
          <b-button
            variant="relief-primary"
            @click="handleAddDiy"
          >
            添加自定义服务
          </b-button>
        </div>
        <extra-service
          ref="extraservice"
          :extraServices="extraServices"
          @calculatePrice="handleCalculatePrice"
        />
      </b-card>
    </b-overlay>
    <div class="empty-box" />
    <div class="fixed-box d-flex justify-content-between align-items-center">
      <div class="fixed-left">
        <span>物流:AU${{ selected.length ? selected[0].estimation : '0.00' }}</span>
        <span>海关费用:AU${{ selected.length && selected[0].tax_fee ? selected[0].tax_fee : '0.00' }}</span>
        <span>超重费:AU${{ selected.length && selected[0].extra_fee ? selected[0].extra_fee : '0.00' }}</span>
        <span>保险费:AU${{ !!this.insSelected && insPrice || '0.00' }}</span>
        <span>额外服务:AU${{ selected.length && Number(extraServicePrice).toFixed(2) || '0.00' }}</span>
      </div>
      <b-button
        v-if="!isSubmitLoading"
        variant="relief-success"
        :disabled="selected.length && declareList.length ? false : true"
        @click="handleVerify"
      >
        提交运单<span v-if="totalSubPrice">(AU${{ Number(totalSubPrice).toFixed(2) }})</span>
      </b-button>
      <b-button
        v-if="isSubmitLoading"
        variant="relief-success"
      >
        <b-spinner
          small
          type="grow"
        />
        Loading...
      </b-button>
    </div>
    <b-modal
      ref="modal-tw"
      cancel-variant="outline-secondary"
      centered
      title="邮寄须知"
    >
      <ul style="padding-left: 10px;">
        <li style="list-style: none;font-size: 16px;color: red;">
          请确认收件人已经完成易立委实名认证，收件人的姓名和联系电话需要与易立委认证一致。如若在台湾清关时，因收件人信息与易立委资料不匹配，需要修正或更改收件人名字等讯息，将产生AU$25.00的重新提交费用。
        </li>
        <li style="font-size: 16px;list-style: none;margin-top:20px;">
          以下情况下，您将需要自行承担税费：
        </li>
        <ol style="padding-left: 20px;">
          <li style="list-style: none;font-size: 16px;margin-top: 10px;">
            1. 超过NT$110.00的税金需要您全额支付。
          </li>
          <li style="list-style: none;font-size: 16px;margin-top: 10px;">
            2. 如果税单原因是频繁进口，将由您全额支付。
          </li>
        </ol>
      </ul>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="relief-primary"
            class="float-right"
            :disabled="countdown > 0"
            @click="handleAgree"
          >
            我已阅读并同意{{ confirmLocalText }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      ref="modal-print"
      cancel-variant="outline-secondary"
      centered
      title="打印"
    >
      <h4>提交成功，是否打印面单?</h4>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="relief-secondary"
            class="mr-2"
            @click="handleCancelPrint"
          >
            取消
          </b-button>
          <b-button
            v-if="next_action === 1 && !printLoading"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="relief-primary"
            @click="handlePrint"
          >
            打印
          </b-button>
          <b-button
            v-else-if="next_action !== 1 && !printLoading"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="relief-primary"
            @click="createLineClear"
          >
            创建lineclear运单
          </b-button>
          <b-button
            v-else
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="relief-primary"
          >
            Loading...
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BTable,
  BAvatar,
  BFormCheckbox,
  BFormRadio,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BSpinner,
  BOverlay,
  BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AddParcel from './components/addParcel.vue'
import Receiver from './components/receiver.vue'
import Declare from './components/declare.vue'
import ExtraService from './components/extraService.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BButton,
    BTable,
    BAvatar,
    BFormCheckbox,
    BFormRadio,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BSpinner,
    AddParcel,
    Receiver,
    Declare,
    BOverlay,
    ExtraService,
    BAlert,
  },
  data() {
    return {
      overlayShow: true,
      isDeclareEdit: false,
      isEdit: false,
      sender: {},
      declareList: [],
      fields: [
        { key: 'radio', label: '' },
        { key: 'logo', label: '' },
        { key: 'name', label: '物流线路' },
        { key: 'estimation', label: '预估运费' },
        { key: 'dispatch_date', label: '下次发货日期' },
        { key: 'duration', label: '运输时间' },
        { key: 'include_tax', label: '是否含税' },
        { key: 'weight', label: '计费重量' },
        { key: 'other', label: '其他信息' },
      ],
      items: null,
      selected: [],
      tagList: [],
      typeList: [],
      shipment_id: '',
      parcels: [],
      isLogiLoading: false,
      insSelected: undefined,
      timer: null,
      client_id: undefined,
      extraServices: [],
      logiItems: [],
      totalSubPrice: 0,
      extraServicePrice: 0,
      isSubmitLoading: false,
      store_id: undefined,
      countdown: 5,
      timer2: null,
      next_action: '',
      printLoading: false,
      is_include_battery: false,
    }
  },
  computed: {
    declareTotalPrice({ declareList }) {
      return declareList.reduce((total, item) => total + Number(item.value), 0)
    },
    senderMobile({ sender }) {
      return `+${sender.sender_phone_code || ''} ${sender.sender_mobile || ''}`
    },
    insPrice({ declareTotalPrice }) {
      if (this.selected[0]) {
        const { insurance_percentage, max_insurance } = this.selected[0]
        const percentage = insurance_percentage / 100
        if (declareTotalPrice >= max_insurance) {
          return (max_insurance * percentage).toFixed(2)
        }
        if (declareTotalPrice < max_insurance) {
          return (declareTotalPrice * percentage).toFixed(2)
        }
      }
      return '0.00'
    },
    recipient() {
      return this.$store.getters['shipment/recipient']
    },
    confirmLocalText({ countdown }) {
      return `${countdown > 0 ? `(${countdown})` : ''}`
    },
  },
  watch: {
    selected() {
      this.handleSubPrice()
    },
    insSelected() {
      this.handleSubPrice()
    },
    extraServices: {
      handler(val) {
        this.extraServicePrice = val.reduce((total, item) => total + Number(item.price) * item.qty, 0)
      },
      immediate: true,
    },
    extraServicePrice() {
      this.handleSubPrice()
    },
  },
  destroyed() {
    clearTimeout(this.timer)
    this.timer = null
    clearInterval(this.timer2)
    this.timer2 = null
  },
  created() {
    const { id } = this.$route.params
    if (id) {
      this.predictionInfo(id)
      this.handleInitPackage()
    }
  },
  mounted() {
    this.logiItems = this.$refs.addParcel.items
  },
  beforeDestroy() {
    this.$store.commit('shipment/resetShipmentRecipient')
  },
  methods: {
    handleEditRecipient() {
      this.isEdit = true
      this.$api.saveOperateLog({
        code: 'agent-recipient-edit',
        content: JSON.stringify(this.$refs.receiver.form),
      })
    },
    handleCancelPrint() {
      this.$refs['modal-print'].hide()
      this.$router.push(`/shipment/detail/${this.shipment_id}`)
    },
    handleCount() {
      this.timer2 = setInterval(() => {
        if (this.countdown > 0) {
          // eslint-disable-next-line
          this.countdown--
        } else {
          clearInterval(this.timer2)
        }
      }, 1000)
    },
    async handleModifyRecipient() {
      const isVerify = await this.$refs.receiver.validationForm()
      if (isVerify) {
        this.isEdit = false
        this.items = null
      }
    },
    handleAddParcelChange() {
      this.items = []
      this.$refs.selectableTable.clearSelected()
      this.selected = []
      this.selected.length = 0
    },
    handleCalculatePrice() {
      this.extraServices = this.$refs.extraservice.items
      this.handleSubPrice()
    },
    handleAddDiy() {
      this.$nextTick(() => {
        this.$refs.extraservice.handleAddDiy()
      })
    },
    handleSubPrice() {
      if (this.selected.length) {
        if (this.insSelected) {
          this.totalSubPrice = Number(this.selected[0].estimation) + Number(this.insPrice) + this.extraServicePrice
        } else {
          this.totalSubPrice = Number(this.selected[0].estimation) + this.extraServicePrice
        }
      } else {
        this.totalSubPrice = 0
      }
    },
    handleDelParcel() {
      this.logiItems = this.$refs.addParcel.items
    },
    async handleInitPackage() {
      try {
        const { data } = await this.$api.packageTags({ storeId: this.store_id || 1 })
        const { data: data2 } = await this.$api.packageTypes({ storeId: this.store_id || 1 })
        this.tagList = data.map(item => ({
          text: item.name,
          value: item.tags_id,
        }))
        this.typeList = data2.map(item => ({
          ...item,
          text: item.parcel_type,
          value: item.type_id,
        }))
      } catch (error) {
        console.log('err', error)
      }
    },
    handleAgree() {
      this.$refs['modal-tw'].hide()
      this.handleSubmit()
    },
    handleVerify() {
      if (this.selected[0].id === 49) {
        this.$refs['modal-tw'].show()
        this.handleCount()
      } else {
        this.handleSubmit()
      }
    },
    async handleSubmit() {
      try {
        this.isSubmitLoading = true
        const declareGoods = this.declareList.map(item => ({
          name: item.name,
          qty: item.qty,
          value: `${item.value}`,
        }))
        const extraServices = this.$refs.extraservice.items.map(item => ({
          name: item.name,
          price: Number(item.price),
          qty: item.qty,
          id: item.id,
          calculated_per: item.charged_per,
        }))
        const recipient = this.$refs.receiver.form
        const newRecipient = Object.assign(recipient, {
          full_name: recipient.recipient_name,
          address1: recipient.address,
          address2: recipient.address_line_2,
          address3: recipient.address_line_3,
        })
        const params = {
          courier_id: `${this.selected[0].id}`,
          parcels: this.handleParcels(),
          estimated_cost: this.selected[0].estimation,
          shipment_id: `${this.shipment_id}`,
          extra_services: extraServices,
          declare_goods: declareGoods,
          insurance_check: !!this.insSelected,
          ...newRecipient,
        }
        const isVerify = await this.$refs.addParcel.validationForm()
        this.$refs.addParcel.initTrHeight()
        if (isVerify) {
          this.overlayShow = true
          const data = await this.$api.submitPreshipment(params)
          this.overlayShow = false
          this.isSubmitLoading = false
          if (data.code === 200) {
            this.next_action = data.data.next_action
            this.$refs['modal-print'].show()
          } else {
            this.isSubmitLoading = false
            this.$bvToast.toast(data.message, {
              title: '提示',
              variant: 'danger',
              solid: true,
              toaster: 'b-toaster-top-center',
            })
          }
        }
      } catch (error) {
        this.overlayShow = false
        console.log('error', error)
      }
    },
    async createLineClear() {
      try {
        this.printLoading = true
        const data = await this.$api.createLineClear({ shipment_id: this.shipment_id })
        this.printLoading = false
        if (data.code === 200) {
          const printData = {
            shipment: {
              isChoose: false,
            },
            declare: {
              isChoose: false,
            },
            lineclear: {
              isChoose: true,
              ...data.data,
            },
          }
          if (data.data) {
            if (data.data.is_default) {
              printData.lineclear.isChoose = data.data.is_default
            } else {
              printData.lineclear.isChoose = false
            }
          }
          localStorage.setItem('print_data', JSON.stringify(printData))
          window.open('/print.html', '_blank')
          this.$router.push(`/shipment/detail/${this.shipment_id}`)
          this.$refs['modal-print'].hide()
        }
      } catch (error) {
        this.printLoading = false
        console.log('error', error)
      }
    },
    async handlePrint() {
      try {
        this.printLoading = true
        const data = await this.$api.shipmentPrint({ id: this.shipment_id })
        this.printLoading = false
        if (data.code === 200) {
          const printData = data.data
          if (printData.declare) {
            if (printData.declare.is_default) {
              printData.declare.isChoose = printData.declare.is_default
            } else {
              printData.declare.isChoose = false
            }
          }
          if (printData.shipment) {
            if (printData.shipment.is_default) {
              printData.shipment.isChoose = printData.shipment.is_default
            } else {
              printData.shipment.isChoose = false
            }
          }
          if (printData.lineclear) {
            if (printData.lineclear.is_default) {
              printData.lineclear.isChoose = printData.lineclear.is_default
            } else {
              printData.lineclear.isChoose = false
            }
          }
          localStorage.setItem('print_data', JSON.stringify(printData))
          window.open('/print.html', '_blank')
          this.$router.push(`/shipment/detail/${this.shipment_id}`)
          this.$refs['modal-print'].hide()
        }
      } catch (error) {
        this.printLoading = false
        console.log('error', error)
      }
    },
    async handleSaveDeclare() {
      if (this.$refs.declare && this.$refs.declare.items.length) {
        const isVerify = await this.$refs.declare.validationForm()
        if (isVerify) {
          this.declareList = this.$refs.declare.items
          this.isDeclareEdit = false
        }
      } else {
        this.$bvModal
          .msgBoxConfirm('请填写申报', {
            cancelVariant: 'outline-danger',
            centered: true,
            buttonSize: 'sm',
          })
      }
    },
    handleRadioChange(e) {
      let idx
      this.items.forEach((v, n) => {
        // eslint-disable-next-line
        if (v.id == e) {
          idx = n
        }
      })
      this.$refs.selectableTable.selectRow(idx)
    },
    async handleSaveRecipient() {
      const isVerify = await this.$refs.receiver.validationForm()
      if (isVerify) {
        this.isEdit = false
        this.items = null
      }
      this.$api.saveOperateLog({
        code: 'agent-recipient-save',
        content: JSON.stringify(this.$refs.receiver.form),
      })
    },
    async predictionInfo(id) {
      try {
        // 在获取新运单信息前，先清空之前的收件人信息
        this.$store.commit('shipment/resetShipmentRecipient')
        const data = await this.$api.predictionInfo({ id })
        this.overlayShow = false
        if (data.code === 200) {
          const {
            recipient, sender, shipmentDeclarationForm: declareList, parcels, extraServices,
          } = data.data
          this.$store.commit('shipment/setShipmentRecipient', recipient)
          this.$api.saveOperateLog({
            code: 'agent-predictionInfo',
            content: JSON.stringify(recipient),
          })
          this.sender = sender
          this.declareList = declareList
          this.shipment_id = data.data.id
          this.client_id = data.data.client_id
          this.parcels = parcels
          this.extraServices = extraServices
          this.store_id = data.data.store_id
          this.insSelected = data.data.hasInsurance ? 'A' : undefined
          this.is_include_battery = data.data.is_include_battery
        } else {
          this.$bvToast.toast(data.msg, {
            title: '提示',
            variant: 'warning',
            toaster: 'b-toaster-top-center',
          })
          this.timer = setTimeout(() => {
            this.$router.go(-1)
          }, 2000)
        }
      } catch (error) {
        this.overlayShow = false
        console.log('error', error)
      }
    },
    handleParcels() {
      const parcels = this.$refs.addParcel.items.map(item => ({
        length: item.length,
        height: item.height,
        parcel_type_id: item.parcel_type_id,
        weight: item.weight,
        width: item.width,
        tag_id: item.parcel_tag_id,
      }))
      return parcels
    },
    async predictionLogistics() {
      try {
        const isVerifyReceiver = await this.$refs.receiver.validationForm()
        const isVerify = await this.$refs.addParcel.validationForm()
        this.$refs.addParcel.initTrHeight()
        if (isVerify && isVerifyReceiver) {
          this.items = []
          this.items.length = 0
          const recipient = this.$store.getters['shipment/recipient']
          const params = {
            parcels: this.handleParcels(),
            // client_id: this.client_id,
            shipment_id: `${this.shipment_id}`,
            country_id: recipient.country_id,
            state_id: recipient.state_id,
            delivery_to: recipient.recipient_name,
            address: recipient.address,
            address_line_2: recipient.address_line_2,
            address_line_3: recipient.address_line_3,
            suburb: recipient.suburb,
            postcode: recipient.postcode,
            contact_number: recipient.contact_number,
            email: recipient.email,
            // company_name: recipient.company_name,
            // id_card: recipient.id_card,
          }
          this.isLogiLoading = true
          this.$api.saveOperateLog({
            code: 'agent-predictionLogistics',
            content: JSON.stringify(params),
          })
          const data = await this.$api.predictionLogistics(params)
          this.isLogiLoading = false
          if (data.code === 200) {
            const { logisticsList } = data.data
            this.items = logisticsList.filter(item => Number(item.estimation) > 0)
            this.items = this.items.map(item => ({
              _showDetails: !!(Number(item.tax_fee) > 0 || Number(item.extra_fee) > 0),
              ...item,
            }))
            if (this.items.length === 1) {
              this.onRowSelected(this.items)
            }
          } else {
            this.$bvToast.toast(data.msg, {
              title: '提示',
              variant: 'warning',
              toaster: 'b-toaster-top-center',
            })
          }
        } else {
          this.$bvToast.toast('请先完成必填项', {
            title: '提示',
            variant: 'warning',
            toaster: 'b-toaster-top-center',
          })
        }
      } catch (error) {
        console.log('error', error)
      }
    },
    onRowSelected(item) {
      if (item.length) {
        this.items.forEach(v => {
        // eslint-disable-next-line
        v.radio = item[0].id
        })
        this.selected = item
      } else if (this.selected.length) {
        this.handleRadioChange(this.selected[0].id)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.declare-title{
  font-size: 18px;
  text-align: center
}
.price{
  text-align: right;
  font-size: 18px;
  margin-bottom: 40px;
}
.text-key-left{
  font-size: 18px;
  display: block;
  width: 140px;
}
.fixed-box{
  background: #fff;
  position: fixed;
  width: calc(100vw - (100vw - 100%) - calc(2rem * 2) - 260px);
  z-index: 12;
  margin: 1.3rem 2rem 0;
  right: 0;
  bottom: 20px;
  padding: 20px;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  border-radius: 0.428rem;
}
.fixed-left{
  font-size: 18px;
  span{
    margin-right: 20px;
  }
}
.empty-box{
  height: 100px;
}
.ins{
  font-size: 18px;
}
.loading-text{
  font-size: 26px;
}
</style>
